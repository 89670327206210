import React, {useEffect} from 'react'
import Colors from "../../components/ui/Colors";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { ClientRol } from '.';
import {Grid, Drawer, List, ListItem, ListItemButton, ListItemText, Typography, 
    IconButton, Box, Button} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import {setUxLanguage, setUserLanguage} from "../../utilityFunctions/AuthUtil";
import swal from "sweetalert";
import { loaderAnimation } from '../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
  
let openLanguage=false;
let openStructure=false;
let openConfigGrace=false;
let openGraceSessions=false;
let openAdvisorClients=false;
let openManageClients=false;
  
export default function HanldeProfile(props) {
    const {loginUser} = props

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const { t, i18n } = useTranslation();

    const [mainRole, setMainRole] = React.useState(null);

    useEffect(() => {
        
        handleAdmin();
    }, []);

    const handleLanguageChange = async (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);
        
        const UserLanguage={
            language: selectedLanguage
        }
        const langResponse = await setUxLanguage(UserLanguage, loginUser.jwtToken);
    };

    const handleUserLanguageChange = async (selectedLanguage) => {
        i18n.changeLanguage(selectedLanguage);

        const UserLanguage={
            language: selectedLanguage
        }
        const langResponse = await setUserLanguage(UserLanguage, loginUser.jwtToken);

    };


    const handleAdmin = () => {

        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            profileOptionsDrawer(loginUser.roles[0]);
            setMainRole(loginUser.roles[0].authority);
        }

    };
    
    const[profileOptions, setProfileOptions] = React.useState("");

    const profileOptionsDrawer = (userAuth) => {

        const userProfile = userAuth.authority;
        console.log("userProfile: ", userProfile);
        setProfileOptions(
            <List sx={{width: "100%"}}>
                {userProfile && userProfile==="USER" &&
                    <>
                        {/* <ListItem disablePadding>
                            <ListItemButton onClick={goToGrace} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.grace")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding>
                            <ListItemButton onClick={goToConsumerInfo} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.yourData")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={GoToConsumerPlan} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.plan")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem disablePadding>
                            <ListItemButton onClick={goToConsumerGoals} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.goals")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding>
                            <ListItemButton onClick={goToRecordMeeting} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.meetingsRecord")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandLanguage} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("Profile.language")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openLanguage ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openLanguage} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleUserLanguageChange("es")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"Español"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleUserLanguageChange("en")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"English"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
                {userProfile && userProfile==="ADVISOR" &&
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={goToAdvisorProf} sx={{justifyContent: "center"}}>
                                <ListItemText primary={t("HandleProfile.profile")} primaryTypographyProps={{variant: "body1"}}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandAdvisorClients} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("HandleProfile.clients")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openAdvisorClients? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openAdvisorClients} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToAdvisorClientsDashboard} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.clientsDashboard")} primaryTypographyProps={{variant: "body1"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToAdvisorClientsSessions} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.clientsSessions")} primaryTypographyProps={{variant: "body1"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandLanguage} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("Profile.language")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openLanguage ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openLanguage} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleUserLanguageChange("es")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"Español"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleUserLanguageChange("en")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"English"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
                {userProfile && userProfile==="ADMIN" &&
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandMangeClients} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("HandleProfile.manageClients")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openManageClients ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openManageClients} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoManageClients} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.manageClients")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToAdminClientsDashboard} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.clientsDashboard")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToConsumers} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.consumers")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToAdvisors} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.advisors")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandStructure} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("HandleProfile.planStructure")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openStructure ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openStructure} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoNewTopic} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.newTopic")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoChangeTopics} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.changeTopics")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoNewAttribute} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.newQuestion")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoChangeAttributes} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.changeQuestions")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoNewAnswer} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.newAnswer")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoChangeAnswers} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.changeAnswers")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={goToConsumerInfo} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.yourData")} primaryTypographyProps={{variant: "body1", color: "GrayText"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandGrace} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("HandleProfile.graceConfig")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openConfigGrace ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openConfigGrace} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoManagePrompts} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.systemPrompts")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandSessions} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("HandleProfile.sessions")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openGraceSessions ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openGraceSessions} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={gotoSessionsList} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={t("HandleProfile.clientsSessions")} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleExpandLanguage} sx={{justifyContent: "center"}}>
                                <Grid container direction="column">
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                        <ListItemText primary={t("Profile.language")} primaryTypographyProps={{variant: "body1"}}/>
                                        {openLanguage ? <ExpandLess /> : <ExpandMore />}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Collapse in={openLanguage} timeout="auto">
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleLanguageChange("es")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"Español"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => handleLanguageChange("en")} sx={{justifyContent: "center"}}>
                                                    <ListItemText primary={"English"} primaryTypographyProps={{variant: "body2"}}/>
                                                </ListItemButton>
                                            </ListItem>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
                
            </List>
        );
    };
    
    const handleExpandStructure = () => {
        openStructure=!openStructure;
        handleAdmin(); 
    };

    const handleExpandLanguage = () => {
        openLanguage=!openLanguage;
        handleAdmin(); 
    };

    const  handleExpandAdvisorClients = () => {
        openAdvisorClients=!openAdvisorClients;
        handleAdmin(); 
    };

    const handleExpandGrace = () => {
        openConfigGrace=!openConfigGrace;
        handleAdmin();
    };

    const handleExpandSessions = () => {
        openGraceSessions=!openGraceSessions;
        handleAdmin();
    };

    const handleExpandMangeClients = () => {
        openManageClients=!openManageClients;
        handleAdmin();
    };

    const goToGrace = () =>{
        history.push({
            pathname: "/consumer/ai-grace",
            state:{
                reference: "grace_system_initial",
            }
        });
    };

    // const goToConsumerInfo = () =>{
    //     history.push({pathname: "/retirement_plan/topics"});
    // };

    const goToConsumerInfo = () =>{
        history.push({pathname: "/consumer/questions_options"});
    };

    const goToConsumerGoals = () =>{
        history.push({pathname: "/consumer/goals"});
    };

    const GoToConsumerPlan = () =>{
        history.push({pathname: "/retirement_plan/dashboard"});
    };

    const goToAdvisorProf = () =>{
        history.push({pathname: "/advisor/profile"});
    };

    const goToAdvisorClientsDashboard = () =>{
        history.push({pathname: "/advisors/client_dashboard"});
    };

    const goToAdvisorClientsSessions = () =>{
        history.push({pathname: "/advisors/clients_sessions"});
    };

    const goToAdminClientsDashboard = () =>{
        history.push({pathname: "/admin/clients_dashboard"});
    };

    const goToConsumers = () =>{
        history.push({pathname: "/admin/reports_consumers"});
    };

    const goToAdvisors = () =>{
        history.push({pathname: "/admin/reports_advisors"});
    };

    const gotoNewTopic = () =>{
        history.push({pathname: "/admin/wellness_structure/new_topic"});
    };

    const gotoNewAttribute = () =>{
        history.push({pathname: "/admin/wellness_structure/new_attribute"});
    };

    const gotoNewAnswer = () =>{
        history.push({pathname: "/admin/wellness_structure/new_answer"});
    };

    const gotoChangeTopics = () =>{
        history.push({pathname: "/admin/wellness_structure/change_topics"});
    };

    const gotoChangeAttributes = () =>{
        history.push({pathname: "/admin/wellness_structure/change_attributes"});
    };

    const gotoChangeAnswers = () =>{
        history.push({pathname: "/admin/wellness_structure/change_answers"});
    };

    const gotoManagePrompts = () =>{
        history.push({pathname: "/admin/grace_system_prompts"});
    };

    const gotoSessionsList = () =>{
        history.push({pathname: "/admin/grace_sessions"});
    };

    const gotoManageClients = () =>{
        history.push({pathname: "/admin/manage_users"});
    };
    
    const goToRecordMeeting = () =>{
        swal({
            title: t("HandleProfile.comingSoon"),
            icon: "info",
            button: "OK",
        });
        // history.push({pathname: "/consumer/speech_to_text"});
        // return;
    };

    const goToConnectAdvisor = () =>{
        history.push({pathname: "/consumer/add_advisor"});
        return;
    };

    const goToReferAFriend = () =>{
        swal({
            title: t("HandleProfile.comingSoon"),
            icon: "info",
            button: "OK",
        });
        return;
    };

    const goToExit = () =>{
        history.push({pathname: "/"});
        return;
    };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

  return (
      <React.Fragment>
        {loading ? loaderAnimation :
            <Grid container>
                <Grid item xs={2} sm={4} md={3} lg={3} sx={{paddingLeft: "1rem"}}>
                    <Box display={{ xs: "flex", sm: "flex", md: "none", lg: "none"}} style={{justifyContent: {xs: "end", sm: "center"}, width: "100%"}}>
                        <IconButton
                            color="inherit"
                            //   edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'flex',  md: "none"} }}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'block', md: "none" },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box'},
                        }}
                        PaperProps={{
                            sx:{
                                marginTop: "5rem",
                                width: {sx: "50%", sm: "35%"},
                            }
                        }}
                        >
                        <Grid container sx= {{width: "50%"}}>
                            <Grid item xs={12}>
                                {profileOptions}
                            </Grid>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "3rem"}}>
                                    <Typography variant="body2" sx={{ color: Colors.primary, fontSize: "12px", fontWeight: "bold"}}>{t("HandleProfile.connectWithAdvisor")}</Typography>
                                </Grid>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.5rem"}}>
                                <Typography variant="body2" sx={{ color: Colors.primary, fontSize: "12px", fontWeight: "bold"}}>{t("HandleProfile.referAFriend")}</Typography>
                            </Grid>
                        </Grid>
                    </Drawer>
                    <Box style={{marginTop: "5rem"}}>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'none', md: 'flex'},
                                '& .MuiDrawer-paper': { boxSizing: 'border-box'}
                            }}
                            PaperProps={{
                                sx:{marginTop: "5rem",width: "20%",}
                            }}
                            open
                            >
                            <Grid container>
                                <Grid item xs={12}>
                                    {profileOptions}
                                </Grid>
                                {mainRole && mainRole==="USER" &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "3rem"}}>
                                    <Button onClick={goToConnectAdvisor} component="label" sx={{backgroundColor: "none", color: Colors.primary, fontSize: "12px", fontWeight: "bold"}}>
                                        {t("HandleProfile.connectWithAdvisor")}
                                    </Button>
                                </Grid>
                                }
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                                    <Button onClick={goToReferAFriend} component="label" sx={{backgroundColor: "none", color: Colors.primary, fontSize: "12px", fontWeight: "bold"}}>
                                        {t("HandleProfile.referAFriend")}
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12} sx={{display: "flex", justifyContent: "center", marginTop: "2rem",}}>
                                    <Box sx={{ backgroundColor: "#242223", color: "white", borderRadius: "50px", padding: "8px 16px", display: "flex", alignItems: "center", gap: "8px", width: "fit-content" }}>
                                        <ArrowForwardIosIcon sx={{ fontSize: "1rem", color: "white" }} />
                                        <Button onClick={goToExit} component="label" sx={{ color: "white", textTransform: "none", padding: 0, minWidth: "unset", fontSize: "0.9rem" }}>
                                            {t("HandleProfile.logOut")}
                                        </Button>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Drawer>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={8} md={9} lg={9}>
                    <ClientRol {...props}/>
                </Grid>
            </Grid>
        }

       </React.Fragment>
  );
}
