import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from '@mui/icons-material/Logout';
import CategoryIcon from "@mui/icons-material/Category";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import MapIcon from "@mui/icons-material/Map";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GroupIcon from "@mui/icons-material/Group";
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import Colors from "../components/ui/Colors";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const NavBarStyle = styled.div({
  display: "flex",
  flexDirection: "row",
  height: "4.7rem",
  minHeight: "4.7rem",
  width: "100%",
  justifyContent: "center",
  backgroundColor: "#242223",
  gap: {xs: "5%", sm: "10%"},
});

const NavItem = styled.div({
  width: "20%",
  display: "flex",
  alignItems: "center",
  color: "white",
  cursor: "pointer",
  textAlign: "center",
});

const NavBar = ({ loginUser, userdata }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const initValues = async () => {
      handleProfile();
    };
    initValues();
  }, []);

  const handleProfile = async () => {
    if (
      loginUser.roles &&
      !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)
    ) {
      setProfile(loginUser.roles[0].authority);
      console.log(loginUser.roles[0].authority);
    }
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = async () => {
    localStorage.clear();
    userdata(null);
    history.push("/");
  }


  const menuItems = [
    { id: "gracePlan", icon: <FollowTheSignsIcon sx={{ color: Colors.primary }} />, text: t("NavBar.gracePlan"), action: () => {handleClose(); history.push("/consumer/llm_choose")} },
    { id: "planCharts", icon: <SignalCellularAltIcon sx={{ color: Colors.primary }} />, text: t("NavBar.planCharts"), action: () => {handleClose(); history.push("/retirement_plan/dashboard")} },
    { id: "retirementJournal", icon: <FeedOutlinedIcon sx={{ color: "#BDBDBD" }} />, text: t("NavBar.retirementJournal"), action: handleClose },
    { id: "meetingsRecord", icon: <VideoCallOutlinedIcon sx={{ color: "#BDBDBD" }} />, text: t("NavBar.meetingsRecord"), action: handleClose },
    { id: "exploreTopics", icon: <TravelExploreOutlinedIcon sx={{ color: "#BDBDBD" }} />, text: t("NavBar.exploreTopics"), action: handleClose },
    { id: "resourcesInsights", icon: <TipsAndUpdatesIcon sx={{ color: "#BDBDBD" }} />, text: t("NavBar.resourcesInsights"), action: handleClose },
    { id: "addAdvisor", icon: <PersonSearchIcon sx={{ color: Colors.primary }} />, text: t("NavBar.connectWithAdvisor"), action: () => {handleClose(); history.push("/consumer/add_advisor")} },
    { id: "friend", icon: <GroupIcon sx={{ color: "#BDBDBD" }} />, text: t("NavBar.referAFriend"), action: handleClose },
  ];

  const navMenus = [  
    { id: "actions", 
      icon: <Avatar alt="Actions" sx={{ backgroundColor: "white", color: "black" }}><CategoryIcon sx={{ fontSize: "1.5rem" }} /></Avatar>,
      label: t("NavBar.navigate")
    },
    { id: "logOut", 
      // to: "/", 
      icon: <Avatar alt="Home" sx={{ backgroundColor: "white", color: "black" }}><LogoutIcon sx={{ fontSize: "1.5rem" }} /></Avatar>,
      action: () => logoutHandler(),
      label: t("NavBar.logout")
    }
  ];

  const roleNavMenus = [
    { id: "home", 
      // to: "/handle_profile", 
      icon: <Avatar alt="Home" sx={{ backgroundColor: "white", color: "black" }}><HomeIcon sx={{ fontSize: "1.5rem" }} /></Avatar>,
      action: () => history.push("/handle_profile"),
      label: t("NavBar.home")
    },
    { id: "logOut", 
      // to: "/", 
      icon: <Avatar alt="Home" sx={{ backgroundColor: "white", color: "black" }}><LogoutIcon sx={{ fontSize: "1.5rem" }} /></Avatar>,
      action: () => logoutHandler(),
      label: t("NavBar.logout")
    },
  ]

  return (
    <NavBarStyle>
      <Grid container>
        {/* Logo Section */}
        <Grid item xs={7} sm={8} sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
          <Box component="img" sx={{ width: { xs: "70%", sm: "20%", md: "20%" }, height: "auto", pl: { xs: "0.9rem", sm: "1rem", md: "1.5rem" }, }} alt="Logo" src="/images/logo-mpk-white.png" />
        </Grid>

        {/* Navigation Menu */}
        <Grid item xs={5} sm={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {profile && profile === "USER" ?
            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>              
                {navMenus.map((menu) => (
                  <Grid key={menu.id} item xs={6} sm={6}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '4px'
                    }}>
                      <NavItem onClick={menu.id === "actions" ? (e)=> handleClickMenu(e) : menu.action}>
                        {menu.icon}
                      </NavItem>
                      <Typography 
                        variant="body2" 
                        sx={{
                          color: "white",
                          fontSize: '0.875rem',
                          lineHeight: 1
                        }}
                      >
                        {menu.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            :
            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {roleNavMenus.map((menu) => (
                  <Grid key={menu.id} item xs={6} sm={6}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '4px'
                    }}>
                      <NavItem onClick={menu.id === "actions" ? (e)=> handleClickMenu(e) : menu.action}>
                        {menu.icon}
                      </NavItem>
                      <Typography 
                        variant="body2" 
                        sx={{
                          color: "white",
                          fontSize: '0.875rem',
                          lineHeight: 1
                        }}
                      >
                        {menu.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          }
          {/* Dropdown Menu */}
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}
            slotProps={{
              paper: {
                sx: {
                  width: "20ch",
                },
              },
            }}
          >
            {menuItems.map((item, index) => (
              <Box key={item.id}>
                <MenuItem onClick={item.action}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Typography variant="body2">{item.text}</Typography>
                </MenuItem>
                {index < menuItems.length - 1 && <Divider />}
              </Box>
            ))}
          </Menu>
        </Grid>
      </Grid>
    </NavBarStyle>
  );
};

export default NavBar;