import React from 'react';
import {Box, Grid, TextField, Typography, Autocomplete, Button, MenuItem,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import highlightsText from "../../Texts/HighlightsText";
import advisorsHighlightsText from '../../Texts/AdvisorsHighlightsText';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {persistPersonalInfo} from '../../utilityFunctions/WellnessUtil';
import Colors from "../../components/ui/Colors";
import {usaStates, usaCitiesByState, loaderAnimation} from "../../Api"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const onboardingInformation = {};

const totalFields = false;

export default function PersonalInformation(props) {

    const {loginUser, userdata, updateNavBarEnabled} = props;

    const { t } = useTranslation();

    let history = useHistory();

    const profileTypes = [
        {
          type: "advisor",
          label: t("PersonalInformation.profileTypes.advisor")
        },
        {
          type: "user",
          label: t("PersonalInformation.profileTypes.user")
        }
    ];
    
    const clientIdTypes = [
        {
          type: "driverLicense",
          label: t("PersonalInformation.clientIdTypes.driverLicense")
        },
        {
          type: "stateIdCard",
          label: t("PersonalInformation.clientIdTypes.stateIdCard")
        },
        {
          type: "greencard",
          label: t("PersonalInformation.clientIdTypes.greencard")
        },
        {
          type: "socialSecurity",
          label: t("PersonalInformation.clientIdTypes.socialSecurity")
        },
        {
            type: "passport",
            label: t("PersonalInformation.clientIdTypes.passport")
        },
    ];

    const alreadyRetired = [
        {
            type: "yes",
            label: t("PersonalInformation.alreadyRetired.yes")
        },
        {
            type: "no",
            label: t("PersonalInformation.alreadyRetired.no")
        }
    ];

    const practiceTypes = [
        {
            type: "independent",
            label: t("PersonalInformation.practices.independent")
        },
        {
            type: "ria",
            label: t("PersonalInformation.practices.ria")
        },
        {
            type: "insuranceAgent",
            label: t("PersonalInformation.practices.insuranceAgent")
        },
    ]

    const[profile, setProfile] = React.useState("");
    const [idType, setIdType] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [idNumber, setIdNumber] = React.useState("");
    const [state, setState] = React.useState("");
    const [city, setCity] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [retired, setRetired] = React.useState("");
    const [zipCode, setZipCode] = React.useState("");
    const [practiceType, setPracticeType] = React.useState("");

    const profileChange = (e) => {
      setProfile(e.target.value);
      onboardingInformation.profile = e.target.value;
      console.log(props?.location?.state?.loginParams);
    };

    const changeFirstName = (e) => {
        onboardingInformation.firstName = e.target.value;
        setFirstName(e.target.value);
    };

    const changeLastName = (e) => {
        onboardingInformation.lastName = e.target.value;
        setLastName(e.target.value);
    }

    const idTypeChange = (e) => {
        onboardingInformation.idType = e.target.value;
      setIdType(e.target.value);
      
    };

    const changeIdNumber = (e) => {
        onboardingInformation.idNumber = e.target.value;
        setIdNumber(e.target.value);
    }

    

    const usaStateChange = (e, value) => {
        if(!value){
            return;
        }

        setState(value);
        onboardingInformation.state = value;
    }

   

    const cityChange = (e, value) => {
        if(!value){
            return;
        }

        setCity(value);
        onboardingInformation.city = value;
    }

    const addressChange = (e) => {
        onboardingInformation.address = e.target.value;
        setAddress(e.target.value);
    }

    const phoneChange = (e) => {
        onboardingInformation.phone = e.target.value;
        setPhone(e.target.value);
    }

    const handleDateChange = (date) => {
        // setSelectedDate(dayjs(date).format('YYYYMMDD'));
        setSelectedDate(date);
        onboardingInformation.birthDateString=dayjs(date).format('YYYYMMDD');
    };

    const retiredChange = (e) => {
        setRetired(e.target.value);
        onboardingInformation.alreadyRetired = e.target.value;
    };

    const zipCodeChange = (e) => {
        setZipCode(e.target.value);
        onboardingInformation.zipCode = e.target.value;
    };

    const practiceTypeChange = (e) => {
        setPracticeType(e.target.value);
        onboardingInformation.practiceType = e.target.value;
    };

    const [disclaimer, setDisclaimer] = React.useState(false);
    const openHighlights = () => {
        setDisclaimer(true);
    };

    const closeHighlights = () => {
        setDisclaimer(false);
    };

    const [advisorsDisclaimer, setAdvisorsDisclaimer] = React.useState(false);
    const openAdvisorHighlights = () => {
        setAdvisorsDisclaimer(true);
    };

    const closeAdvisorsHighlights = () => {
        setAdvisorsDisclaimer(false);
    };

    const submitPersonalInfo = async () => {

        onboardingInformation.password=props?.location?.state?.loginParams?.password;

        setLoading(true);
        const response = await persistPersonalInfo(onboardingInformation, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            
            swal({
                title: "Error",
                text: t("PersonalInformation.errorSubmiting"),
                icon: "error",
                button: "OK",
            });
            return;
        }

        updateNavBarEnabled(true);

        userdata({
            accountId: response.username,
            ilpBearerToken: response.ilpBearerToken,
            totpToken: response.totpToken,
            jwtToken: response.accessToken,
            profileCompleted: response.profileCompleted,
            roles: response.roles,
            firstName: response.firstName,
        });

        if(onboardingInformation.profile==="advisor"){
            history.push({pathname: "/handle_profile"});
            return;
        }

        history.push({pathname: "/consumer/llm_choose"});
        return;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: {xs: "start", sm: "start"},
                width: "95%",
                height: "99%",
                // textAlign: "center",
                marginTop: {xs: "1.3rem", sm: "1rem"},
                }}
            >
                {/* {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : */}
                {loading ? loaderAnimation:
                    <>
                        <Grid container sx={{ position: "absolute", top: 0, left: 0}}>
                            <Grid item xs={12}>
                                <Box 
                                    component="img"
                                    sx={{
                                    width: {xs:"80%", sm:"30%", md: "20%"},
                                    height: {xs:"auto", sm:"auto", md: "auto"},
                                    }}
                                    alt="Logo"
                                    src="/images/logo-mpk.png"
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{position: "absolute", bottom: 30}}>
                            <Grid item xs={0} sm={12} md={12} sx={{display: {xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                                <Typography variant="body2" sx={{color: "black"}}>{t("Register.footerText")}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{display: "flex", justifyContent: "center", width: "90%", marginTop: {xs: "2rem", sm: "1rem"}}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: "1rem", marginBottom: "1.5rem"}}>
                                <Typography variant="h5">{t("PersonalInformation.personalInformation")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <TextField
                                    sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                                    variant="standard"
                                    fullWidth={true}
                                    select
                                    label={<Typography variant="body2">{t("PersonalInformation.clientType")}</Typography>}
                                    value={profile}
                                    onChange={(e)=>profileChange(e)}
                                    autoComplete="off"
                                >
                                    {profileTypes.map((typeEl) => (
                                        <MenuItem key={typeEl.type} value={typeEl.type}>
                                            {typeEl.label}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </Grid>
                            {profile && !!totalFields &&
                                <>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            fullWidth={true}
                                            label={<Typography variant="body2">{t("PersonalInformation.name")}</Typography>}
                                            value={firstName}
                                            onChange={(e)=> changeFirstName(e)}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            fullWidth={true}
                                            label={<Typography variant="body2">{t("PersonalInformation.lastName")}</Typography>}
                                            value={lastName}
                                            onChange={(e)=> changeLastName(e)}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            sx={{textAlign: "start"}}
                                            variant="standard"
                                            fullWidth={true}
                                            select
                                            label={<Typography variant="body2">{t("PersonalInformation.idType")}</Typography>}
                                            value={idType}
                                            onChange={(e)=>idTypeChange(e)}
                                            autoComplete="off"
                                        >
                                            {clientIdTypes.map((typeEl) => (
                                                <MenuItem key={typeEl.type} value={typeEl.type}>
                                                    {typeEl.label}
                                                </MenuItem>
                                            ))}
                                        </TextField> 
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            variant="standard"
                                            fullWidth={true}
                                            label={<Typography variant="body2">{t("PersonalInformation.idNumber")}</Typography>}
                                            value={idNumber}
                                            onChange={(e)=> changeIdNumber(e)}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete sx={{ width: "100%"}}
                                            options={usaStates}
                                            getOptionLabel={(option) => option}
                                            clearOnEscape
                                            autoComplete = {true}
                                            noOptionsText = {t("PersonalInformation.nonState")}
                                            fullWidth
                                            onChange={usaStateChange}
                                            renderInput={(params) => (
                                            <TextField  {...params} label={t("state")} variant="standard" />
                                            )}
                                        />
                                    </Grid>
                                    
                                    {state &&
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete sx={{ width: "100%"}}
                                                options={usaCitiesByState.find((el) => el.state === state).cities}
                                                // getOptionLabel={(option) => option.cities}
                                                clearOnEscape
                                                autoComplete = {true}
                                                noOptionsText = {t("PersonalInformation.nonCity")}
                                                fullWidth
                                                onChange={cityChange}
                                                renderInput={(params) => (
                                                <TextField  {...params} label={t("city")} variant="standard" />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {city &&
                                        <>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                variant="standard"
                                                fullWidth={true}
                                                label={<Typography variant="body2">{t("PersonalInformation.address")}</Typography>}
                                                value={address}
                                                onChange={(e)=> addressChange(e)}
                                                autoComplete="off"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                variant="standard"
                                                label={<Typography variant="body2">{t("PersonalInformation.phone")}</Typography>}
                                                fullWidth
                                                value={phone}
                                                autoComplete="given-phone"
                                                onChange={(e) => phoneChange(e)}
                                                // inputProps={{ maxLength: 10, minLength: 10}}
                                            />
                                        </Grid>
                                        </>
                                    }
                                    { profile && idType && firstName && lastName && idNumber && state && city && address && phone &&
                                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <Button onClick={submitPersonalInfo} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                                {t("PersonalInformation.submit")}
                                            </Button>
                                        </Grid>
                                    }
                                </>
                            }
                            {profile && !totalFields &&
                                <>
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        fullWidth={true}
                                        label={<Typography variant="body2">{t("PersonalInformation.name")}</Typography>}
                                        value={firstName}
                                        onChange={(e)=> changeFirstName(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        variant="standard"
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        fullWidth={true}
                                        label={<Typography variant="body2">{t("PersonalInformation.lastName")}</Typography>}
                                        value={lastName}
                                        onChange={(e)=> changeLastName(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                {profile === "advisor" &&
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        fullWidth={true}
                                        label={<Typography variant="body2">{t("PersonalInformation.address")}</Typography>}
                                        value={address}
                                        onChange={(e)=> addressChange(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                }
                                {profile === "advisor" &&
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        label={<Typography variant="body2">{t("PersonalInformation.phone")}</Typography>}
                                        fullWidth
                                        value={phone}
                                        autoComplete="given-phone"
                                        onChange={(e) => phoneChange(e)}
                                        // inputProps={{ maxLength: 10, minLength: 10}}
                                    />
                                </Grid>
                                }
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <Autocomplete sx={{  width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        options={usaStates}
                                        getOptionLabel={(option) => option}
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {t("PersonalInformation.nonState")}
                                        fullWidth
                                        onChange={usaStateChange}
                                        renderInput={(params) => (
                                        <TextField  {...params} label={t("state")} variant="standard" />
                                        )}
                                    />
                                </Grid>
                                {profile === "advisor" &&
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        label={<Typography variant="body2">{t("PersonalInformation.zipCode")}</Typography>}
                                        fullWidth
                                        value={zipCode}
                                        onChange={(e) => zipCodeChange(e)}
                                    />
                                </Grid>
                                }
                                {profile === "advisor" &&
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        fullWidth={true}
                                        select
                                        label={<Typography variant="body2">{t("PersonalInformation.practiceType")}</Typography>}
                                        value={practiceType}
                                        onChange={(e)=>practiceTypeChange(e)}
                                        autoComplete="off"
                                    >
                                        {practiceTypes.map((typeEl) => (
                                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                                {typeEl.label}
                                            </MenuItem>
                                        ))}
                                    </TextField> 
                                </Grid>
                                }
                                {profile === "user" &&
                                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                                    <TextField
                                        sx={{textAlign: "start", width: {xs: "90%", sm: "90%", md: "90%"}}}
                                        variant="standard"
                                        fullWidth={true}
                                        select
                                        label={<Typography variant="body2">{t("PersonalInformation.retiredLabel")}</Typography>}
                                        value={retired}
                                        onChange={(e)=>retiredChange(e)}
                                        autoComplete="off"
                                    >
                                        {alreadyRetired.map((typeEl) => (
                                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                                {typeEl.label}
                                            </MenuItem>
                                        ))}
                                    </TextField> 
                                </Grid>
                                }
                                {profile === "user" &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: {xs: "1rem", sm: "1.5rem"}}}>
                                        <Grid container direction="column" sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Grid item sx={{display: "flex", justifyContent: "center", alignItems: "end"}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        label={t("PersonalInformation.dateOfBirth")}
                                                        inputFormat="MM/DD/YYYY" // Standard English format
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        renderInput={(params) => 
                                                        <TextField {...params} 
                                                            variant="standard"
                                                            fullWidth
                                                            sx={{width: {xs: "90%", sm: "80%", md: "80%"}}}
                                                        />}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item sx={{display: "flex", justifyContent: "center", alignItems: "start", textAlign: "center", mt: {xs: "0.5rem", sm: "0.5rem"}}}>
                                                <Typography variant="body2" sx={{fontWeight: "bold",}}>Why do we need this?</Typography>
                                            </Grid>
                                            <Grid item sx={{display: "flex", justifyContent: "center", alignItems: "start", textAlign: "center", width:{xs: "60%", sm: "25%"}}}>
                                                <Typography variant="body2" sx={{fontSize: "0.75rem"}}>Your exact date of birth shapes retirement rules and benefits. We want to ensure we guide you perfectly on your journey!</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {profile === "user" &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", marginTop: "0.5rem", justifyContent: "center"}}>
                                        <Button onClick={openHighlights} component="label" sx={{backgroundColor: "none", color: Colors.primary, fontSize: "0.8rem", fontWeight: "bold"}}>
                                            {t("PersonalInformation.highlights")}
                                        </Button>
                                    </Grid>
                                }
                                {profile === "advisor" &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", marginTop: "0.5rem", justifyContent: "center"}}>
                                        <Button onClick={openAdvisorHighlights} component="label" sx={{backgroundColor: "none", color: Colors.primary, fontSize: "0.8rem", fontWeight: "bold"}}>
                                            {t("PersonalInformation.advisorHighlights")}
                                        </Button>
                                    </Grid>
                                }
                                
                                {/* {((profile ==="user" && firstName && lastName && selectedDate && state && retired) || (profile ==="advisor" && firstName && lastName && state && address && phone && zipCode && practiceType)) && */}
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={submitPersonalInfo} variant="contained" sx={{backgroundColor: Colors.secondary, marginTop: "0.7rem",}}>
                                            {t("PersonalInformation.submit")}
                                        </Button>
                                    </Grid>
                                {/* } */}
                                <Grid item xs={12} sm={0} md={0} sx={{display: {xs: "flex", sm: "none", md: "none"}, justifyContent: "center", alignItems: "center"}}>
                                    <Typography variant="body2" sx={{color: "black", fontSize: "0.7rem"}}>{t("Register.footerText")}</Typography>
                                </Grid>
                                {/* {profile ==="advisor" && firstName && lastName && selectedDate && state && retired &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={submitPersonalInfo} variant="contained" sx={{backgroundColor: Colors.secondary, marginTop: "2rem",}}>
                                            {t("PersonalInformation.submit")}
                                        </Button>
                                    </Grid>
                                } */}
                                </>
                            }
                        </Grid>
                        <Dialog open={disclaimer} onClose={closeHighlights}>
                            <DialogTitle>{t("PersonalInformation.highlights")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
                            
                                {highlightsText}

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeHighlights} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={advisorsDisclaimer} onClose={closeAdvisorsHighlights}>
                            <DialogTitle>{t("PersonalInformation.highlights")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
                            
                                {advisorsHighlightsText}

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeAdvisorsHighlights} color="primary">
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
                </Box>
        </Box>
    )
}
