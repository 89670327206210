import React from 'react';
import { loaderAnimation } from '../../../Api';
import {getTopicSummary} from '../../../utilityFunctions/WellnessUtil';
import {Box} from "@mui/material";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export default function LlmChoose(props) {

    const{loginUser} = props;
    const history = useHistory();

    React.useEffect(() => {
        const init = async () => {
            await initialValues();
        }
        init();
    }, []);

    const initialValues = async () => {

        const topicId = props?.location?.state?.topicId || 124;
        // const topicId = props?.location?.state?.topicId || 49372; //Test env
        
        const response = await getTopicSummary({topicId: topicId}, loginUser.jwtToken);

        if(!response){
            history.push({
                pathname: "/retirement_plan/grace_form",
                state: {
                    topicId: topicId,
                    reference: "form_system_initial",
                    selectedTopicIndex: props?.location?.state?.selectedTopicIndex || 0
                }
            });
            return;
        };

        history.push({
            pathname: "/consumer/ai-grace",
            state:{
                reference: "grace_system_initial",
                topicSummary: response,
                topicId: topicId,
                selectedTopicIndex: props?.location?.state?.selectedTopicIndex || 0
            }
        });
        return;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loaderAnimation}
            </Box>
        </Box>
    )
}
