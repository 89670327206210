const highlightsText = `
Privacy & AI Highlights

- Your Privacy Comes First. We are committed to keeping
your personal information secure. Any data you share is
used solely to improve your experience, and we never sell
your information.
- Beta Mode Transparency. As we are in Beta, some
features are still evolving. Your feedback is essential to
making MyPlanKeeper™ better every day.
-AI with Ethics in Mind. Our platform uses AI to provide
personalized insights, but it is not perfect. If you notice
any inaccuracies, please report them to
grace@myplankeeper.com.
- Your Data, Your Control. You decide what information to
share, how it is used, and who has access. Your data is
always yours to manage.
- Safety is Our Priority. We have implemented rigorous
security measures to protect your information from
unauthorized access.
`;

export default highlightsText;