import React, {useRef} from 'react';

export function useAudioStream() {
    
    const audioContextRef = useRef(null);
    const sourceRef = useRef(null);
    const streamRef = useRef(null);               // Persist the media stream
    const mediaStreamSourceRef = useRef(null);    // Persist the media stream source
    const audioProcessorNodeRef = useRef(null);   // Persist the audio processor node
    const audioChunksRef = useRef([]);            // Persist audio chunks
    const [isSourceActive, setIsSourceActive] = React.useState(false); // Track sourceRef state

    const startRecording = async () => {
        stopPlayingAudio();

        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1,
                    echoCancellation: true,
                    noiseSuppression: true,
                },
            });

            const audioContext = new AudioContext();
            audioContextRef.current = audioContext;

            console.log(audioContext.sampleRate);

            // Set up the audio worklet
            if (audioContext.audioWorklet) {
                await audioContext.audioWorklet.addModule('/audio-processor.js');
                mediaStreamSourceRef.current = audioContext.createMediaStreamSource(streamRef.current);
                audioProcessorNodeRef.current = new AudioWorkletNode(audioContext, 'audio-processor');

                mediaStreamSourceRef.current.connect(audioProcessorNodeRef.current);
                audioProcessorNodeRef.current.connect(audioContext.destination);

                audioProcessorNodeRef.current.port.onmessage = (event) => {
                    const audioBuffer = event.data; // Get the Int16Array buffer
                    audioChunksRef.current.push(audioBuffer); // Accumulate the audio buffer in an array
                    
                    console.log('Received audio chunk:', audioBuffer.length);
                };


            } else {
                console.error("AudioWorklet is not supported in this browser.");
            }
        } catch (err) {
            console.error("Error accessing the microphone or initializing audio stream:", err);
        }
    };

    const stopRecording = async () => {
        // Add logging to see what we've collected
        console.log('Total chunks collected:', audioChunksRef.current.length);
        console.log('Total audio length:', audioChunksRef.current.reduce((acc, chunk) => acc + chunk.length, 0));

        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }

        if (audioProcessorNodeRef.current) {
            audioProcessorNodeRef.current.disconnect();
            audioProcessorNodeRef.current.port.onmessage = null;
            audioProcessorNodeRef.current = null;
        }

        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
            if (mediaStreamSourceRef.current) {
                mediaStreamSourceRef.current.disconnect();
                mediaStreamSourceRef.current = null;
            }
            audioContextRef.current.close().then(() => {
                console.log('AudioContext closed');
                audioContextRef.current = null;
            });
        }

        const flattenedBuffer = new Int16Array(audioChunksRef.current.reduce((acc, chunk) => acc + chunk.length, 0));
        let offset = 0;
        audioChunksRef.current.forEach(chunk => {
            flattenedBuffer.set(chunk, offset);
            offset += chunk.length;
        });

        // console.log(audioChunksRef.current);
        // console.log(Array.from(flattenedBuffer));
        

        const audioRequest = {
            sampleRate: audioContextRef.current.sampleRate,
            audioData: Array.from(flattenedBuffer),
        };

        // Add these logs
        console.log('Sample rate:', audioRequest.sampleRate);
        console.log('Audio data length:', audioRequest.audioData.length);
        console.log('Duration (seconds):', audioRequest.audioData.length / audioRequest.sampleRate);
        
        // Log some sample values to check for truncation
        console.log('First 10 samples:', audioRequest.audioData.slice(0, 10));
        console.log('Last 10 samples:', audioRequest.audioData.slice(-10));

        audioChunksRef.current = [];

        return audioRequest;
    };

    const playReceivedAudio = async (base64Audio) => {
        const audioBlob = new Blob([Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);

        await enableAudioContext();

        const audioData = await audioBlob.arrayBuffer();
        const audioBuffer = await audioContextRef.current.decodeAudioData(audioData);

        if (sourceRef.current) {
            sourceRef.current.stop();
        }

        const source = audioContextRef.current.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContextRef.current.destination);
        source.start(0);

        sourceRef.current = source;
        setIsSourceActive(true); // Explicitly set state to true
    };

    const enableAudioContext = async () => {
        if (!audioContextRef.current) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContextRef.current = audioContext;
        }

        if (audioContextRef.current.state === 'suspended') {
            await audioContextRef.current.resume();
        }
    };

    const stopPlayingAudio = () => {
        if (sourceRef.current) {
            sourceRef.current.stop();
            sourceRef.current = null;  // Reset the sourceRef
            setIsSourceActive(false); // Explicitly set state to true
        }
    };

    return { startRecording, stopRecording, playReceivedAudio, stopPlayingAudio, isSourceActive };
}