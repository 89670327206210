import {wupoServerUri} from '../Api';

const getAllUsernames = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllUsernames`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const getSingleUser = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getSingleUser`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const createWellnessTopic = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessTopic`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getWellnessTopic = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/wellnessTopic`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getAllWellnessTopic = async(jwtToken) => {
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/allWellnessTopic`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createWellnessAttribute = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessAttribute`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createWellnessChoice = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessChoice`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getAnswersByTopic = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getWellnessAnswersByTopic`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const createWellnessAttributeAnswer = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createWellnessAttributeAnswers`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const persistPersonalInfo = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createPersonalInfo`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const calculateUserTopicScores = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/calculateUserTopicScores`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessTopic = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessTopic`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessAttribute = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessAttribute`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const modifyWellnessChoice = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/modifyWellnessChoice`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const createChatSession = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createChatSession`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const manageChatCompletions = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manageChatCompletions`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
}

const updateChatSessionSystemPrompts = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateChatSessionSystemPrompts`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    // console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const createQuestionsChatSession = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/createQuestionsChatSession`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const manageQuestionsChatFlow = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/manageQuestionsChatFlow`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const getAllSystemPrompts = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllSystemPrompts`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const updateSystemPrompts = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateSystemPrompts`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const saveAdvisorClientMapping = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/saveAdvisorClientMapping`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const getAllAdvisorClientsInfo = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllAdvisorClientsInfo`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const getAllClientAdvisorsInfo = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllClientAdvisorsInfo`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const getAllAdvisorsUsernames = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllAdvisorsUsernames`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const calculateAdvisorUserTopicScores = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/calculateAdvisorUserTopicScores`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const handleBlockUser = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/handleBlockUser`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const changePersonalInfo = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/changePersonalInfo`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

const audioInputFileUpload = async (formData, jwtToken) => {
  
  console.log(formData.get("audioFile"));

  const response = await  fetch(`${wupoServerUri.devnet}/api/secure/audioInput`, {
        method: "POST",
        body: formData,
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
        },
  }).catch((error) => {
      console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200){

      return "ok";
  }
  else{
      return null;
  }
};

const handleAudioStreaming = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/handleAudioStreaming`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const jsonResponse = await response.json();
    console.log(jsonResponse);

    return jsonResponse;
  }

  else {
    return null;
  }
};

const deleteWellnessUser = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/deleteWellnessUser`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    // const jsonResponse = await response.json();
    // console.log(jsonResponse);

    return "ok";
  }

  else {
    return null;
  }
};

const getTopicSummary = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getTopicSummary`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const jsonResponse = await response.json();
    console.log(jsonResponse);

    return jsonResponse;
  }

  else {
    return null;
  }
};

const calculateProgressScores = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/calculateProgressScores`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const product = await response.json();
    console.log(product);

    return product;
  }

  else {
    return null;
  }
};

export {createWellnessTopic, getWellnessTopic, getAllWellnessTopic, createWellnessAttribute, 
    createWellnessChoice, getAnswersByTopic, createWellnessAttributeAnswer, persistPersonalInfo,
    calculateUserTopicScores, modifyWellnessTopic, modifyWellnessAttribute, modifyWellnessChoice,
    createChatSession, manageChatCompletions, updateChatSessionSystemPrompts, createQuestionsChatSession,
    manageQuestionsChatFlow, getAllSystemPrompts, updateSystemPrompts, getAllUsernames, getSingleUser,
    saveAdvisorClientMapping, getAllAdvisorClientsInfo, getAllClientAdvisorsInfo, getAllAdvisorsUsernames,
    calculateAdvisorUserTopicScores, changePersonalInfo, handleBlockUser, audioInputFileUpload, handleAudioStreaming,
    deleteWellnessUser, getTopicSummary, calculateProgressScores};