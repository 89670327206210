const advisorsHighlightsText = `
Privacy & AI Highlights for Advisors

- User Data Stays Private. We respect your clients’ trust.
User data is never shared or accessed without their
explicit consent—you remain in full control of your client
relationships.
- Your Privacy Matters Too. Any information you provide as
an advisor is kept secure and used only to enhance your
experience with MyPlanKeeper™
- Beta Mode Transparency. MyPlanKeeper™ is currently in
Beta. As we refine our platform, your feedback is crucial.
Please share any suggestions or issues at
grace@myplankeeper.com.
- AI You Can Trust. Our AI helps streamline insights for your
clients, but it’s not infallible. If you spot inaccuracies, let
us know so we can improve together.
- Security at Every Level. We’ve implemented advanced
security protocols to protect both advisor and client data
from unauthorized access.
`;

export default advisorsHighlightsText;